/* eslint-disable react-hooks/exhaustive-deps */
import dynamic from "next/dynamic";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Image from "next/image";
import { useRouter } from "next/router";
import Typography from "@mui/material/Typography";

import { useDispatch, useSelector } from "react-redux";

import formatKeyword from "@/features/jobSearch/hooks/formatKeyword";
import { updateAuthTabStatus } from "@/store/slices/commonSlice";
import { setSignUpOpen } from "@/store/slices/SignupSlice";
import { JOB_COUNT, JOB_GIVERS, JOB_SEEKERS } from "@/constant";
import Search from "@/features/home/components/search";

import checkBot from "@/hooks/botCheck";
import Stack from "@mui/material/Stack";
import Link from "next/link";

const SeoHead = dynamic(() => import("@/features/home/components/seoHead"), {
  ssr: true,
});
const Loglist = dynamic(
  () => import("@/features/home/components/desktop/v1/loglist"),
  { ssr: false }
);

const Testimonials = dynamic(
  () => import("@/features/home/components/testimonials"),
  { ssr: false }
);

const BASE_URL1 = process?.env?.NEXT_PUBLIC_CANONICAL_DOMAIN + "/";
const site_url = process?.env?.NEXT_PUBLIC_CANONICAL_DOMAIN;
const cdn_url = process?.env?.NEXT_PUBLIC_CDN;
const total_job_seekers = new Intl.NumberFormat('en-IN').format(JOB_SEEKERS);

function Home({ device, userAgent }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { open: commonOpen } = useSelector((state) => state.commonReducer);
  const { open: loginOpen } = useSelector((state) => state.login);
  const { open: registerOpen } = useSelector((state) => state.signUp);

  const [canOpenPopup, setCanOpenPopup] = useState(true); // New state to control popup opening
  const [scrollDetected, setScrollDetected] = useState(false);
  const [popActiveStatus, setPopActiveStatus] = useState(true);
  const [check, setCheck] = useState(false);
  const [outsideClick, setOutsideClick] = useState(false);

  useEffect(() => {
    // Use this effect to reset states when navigating to home page
    const resetStates = () => {
      setCanOpenPopup(false);
      setScrollDetected(false);
    };

    router.events.on('routeChangeComplete', (url) => {
      if (url === '/') {
        resetStates();
      }
    });

    return () => {
      router.events.off('routeChangeComplete', resetStates);
    }
  }, [router.events]);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollDetected) {
        setScrollDetected(true);
        if (!commonOpen && !loginOpen && !registerOpen) {
          setCanOpenPopup(true); // Set to open after scroll
        }
      }
    };

    const handleSearchFocus = () => {
      setCanOpenPopup(false); // Prevent popup if the user starts typing
    };

    const handleLinkClick = (e) => {
      if (e.target.tagName === 'A') {
        setCanOpenPopup(false); // Prevent popup if a link is clicked
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('focusin', handleSearchFocus);
    document.addEventListener('click', handleLinkClick);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('focusin', handleSearchFocus);
      document.removeEventListener('click', handleLinkClick);
    };
  }, [scrollDetected, commonOpen, loginOpen, registerOpen]);

  useEffect(() => {
    if (scrollDetected && canOpenPopup) {
      const timer = setTimeout(() => {
        dispatch(updateAuthTabStatus("Seeker"));
        dispatch(setSignUpOpen(true));
        setCanOpenPopup(false); // Prevent further automatic popups
      }, 5000); // Trigger popup 5 seconds after scroll

      return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [scrollDetected, canOpenPopup, dispatch]);

  useEffect(() => {
    document.body.classList.remove('overflowhidden', popActiveStatus);
  }, [popActiveStatus])


  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("event", "none");
    const handleClick = (e) => {
      localStorage.setItem("event", e.target.id === "gtm-btn-browser" ? "none" : "action");
    };
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);


  const [increasingNumbers, setIncreasingNumbers] = useState({
    jobGivers: 0,
    jobSeekers: 0,
    jobCount: 0,
  });

  const incrementValues = {
    jobGivers: 150,
    jobSeekers: 8000,
    jobCount: 200,
  };

  const targetNumbers = {
    jobGivers: +JOB_GIVERS,
    jobSeekers: +JOB_SEEKERS,
    jobCount: +JOB_COUNT,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIncreasingNumbers((prev) => {
        const updated = { ...prev };
        let isDone = true;

        for (const key in targetNumbers) {
          if (prev[key] < targetNumbers[key]) {
            updated[key] = Math.min(prev[key] + incrementValues[key], targetNumbers[key]);
            isDone = false;
          }
        }

        if (isDone) clearInterval(interval);
        return updated;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <SeoHead
        title={
          "Teaching Jobs, Teacher Jobs Vacancy, Non-Teaching Jobs Near Me - Apply Online on Jobs in Education"
        }
        keywords={"jobs"}
        description={
          "Get access to online teaching jobs, tutor jobs, assistant professor vacancies and teacher jobs vacancy near you with Jobs in Education. We have teaching jobs to offer for English, Computers, Maths, Science etc for Pre Schools, Schools, Colleges, Private Coachings - Part-time, Remote and Full-time mode in India."
        }
        canonicalUrl={`${BASE_URL1}`}
        botCheck={!checkBot(userAgent.toString())}
      />
      <Box sx={{
        background: "#E9F3FF",
        backgroundImage: "linear-gradient(#E9F3FF, #fff)",
        padding: { xs: "24px 0 32px", md: "64px 0" },
        borderBottom: "1px solid #D7DDE5",
      }}>

          {device === "mobile" ? (
            <Box
              sx={{
                padding:"0 16px 16px",
                borderBottom:"2px solid #fff",
                width: "100%",
                position: "relative",
                transition: "all .2s",
                marginBottom: "20px",
                maxWidth: "100%",
                zIndex: 9,
                "& .MuiInput-input": {
                  p: "0",
                },
                "& .MuiInput-root": {
                  "::before": {
                    content: "none",
                  },
                  "::after": {
                    content: "none",
                  },
                },
              }}
            >
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                sx={{
                    padding: "4px 4px 4px 12px",
                    bgcolor: "#fff",
                    boxShadow: "0px 2px 5px 0px #0000000F",
                    border: "1px solid #DEDEDF",
                    borderRadius: "8px",
                    "& input::placeholder": {
                      fontSize:{ xs: "14px", md: "16px" },
                    }
                }}
              >
                <Search router={router} formatKeyword={formatKeyword} />
              </Box>
            </Box>
          ) : (
            <></>
          )}

          <Container maxWidth="lg">
          <Typography component="h1" fontWeight={600} sx={{ fontSize: { md: "60px", xs: "22px" }, lineHeight:{md:1.5, xs:1.2}, mb:1 }} m={0} textAlign="center">
            Find the Best Jobs <Typography variant="span" fontWeight={300}>in Education</Typography>
          </Typography>
          <Typography
            fontWeight={400}
            sx={{
              fontSize: { md: '18px', xs: '14px' },
              mb: 2,
              margin: '0 auto',
            }}
            textAlign="center"
            color="#555555"
          >
            On India's Most Comprehensive Job Search Platform for Education
          </Typography>
          {/* <Typography fontWeight={400} sx={{ fontSize: { md: "18px", xs: "14px" }, mb:2, maxWidth:{xs:"300px", md:"100%",}, margin:{md:"0 0 16px", xs:"0 auto"} }} textAlign="center" color="#555555">
            On India's Most Comprehensive Job Search Platform for Education
          </Typography> */}

          {device === "desktop" ? (
            <>
            <Box display="flex" justifyContent="center" mt={3}>
            <Box
              onClick={() => {
                dispatch(setSignUpOpen(true));
                dispatch(updateAuthTabStatus("Seeker"));
              }}
              sx={{
                fontSize: "16px",
                mr: 2,
                borderRadius: "8px",
                backgroundColor: "#2A5798",
                color: "#fff",
                padding: "11px 24px",
                fontWeight: "500",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                border: "1px solid #2A5798",
                boxShadow: "0px 4px 4px -1px rgba(0, 0, 0, 0.05)",
              }}
            >
              Register to Get Free Job Alerts on <i className="icon-whatsappfill" style={{ marginLeft: "8px" }} />
            </Box>
            <Box
              onClick={() => {
                dispatch(setSignUpOpen(true));
                dispatch(updateAuthTabStatus("Employer"));
              }}
              sx={{
                display: "inline-flex",
                fontSize: "16px", borderRadius: "8px", backgroundColor: "#fff", color: "#2A5798", padding: "10px 22px", fontWeight: "400", alignItems: "center", justifyContent: "center", cursor: "pointer", border: "1px solid #2A5798", boxShadow: "0px 4px 4px -1px rgba(0, 0, 0, 0.1)",
              }}
            >
              Hire Talent
            </Box>
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                border: "1px solid #DEDEDF",
                padding: "0.8rem 0.5rem 0.8rem 1.5rem",
                borderRadius: "15px",
                width: "100%",
                position: "relative",
                transition: "all .2s",
                boxShadow: "0px 6px 20px #00000014",
                maxWidth: "850px",
                m: "48px auto 0",
                zIndex: 9,
                "& .MuiInput-input": {
                  p: "0",
                },
                "& .MuiInput-root": {
                  "::before": {
                    content: "none",
                  },
                  "::after": {
                    content: "none",
                  },
                },
              }}
            >
              <Box
                display="flex"
                width="100%"
                alignItems="center"
              >
                <Search router={router} formatKeyword={formatKeyword} />
              </Box>
            </Box>
            </>
          ) : (
            <></>
          )}
          <Box
              sx={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: { xs: "320px", md: "700px" },
              margin: { xs: "32px auto 0", md: "48px auto 0" },
            }}
          >
            <Box fontWeight={600} sx={{ fontSize: { md: "40px", xs: "18px", width:"30%" } }}>
              <Box
                sx={{
                  borderLeft: "1px solid #D7DDE5",
                  minHeight: "25px",
                  lineHeight: 1,
                  paddingLeft: { md: "20px", xs: "8px" },
                }}
              >
                {new Intl.NumberFormat("en-IN").format(increasingNumbers.jobGivers)}+
              </Box>
              <Typography
                sx={{
                  fontSize: { md: "16px", xs: "13px" },
                  color: "#555",
                  paddingLeft: { md: "20px", xs: "10px" },
                  mt: { xs: "0", md: 1 },
                }}
              >
                Job Givers
              </Typography>
            </Box>
            <Box fontWeight={600} sx={{ fontSize: { md: "40px", xs: "18px" }, width:"40%" }}>
              <Box
                sx={{
                  borderLeft: "1px solid #D7DDE5",
                  minHeight: "25px",
                  lineHeight: 1,
                  paddingLeft: { md: "20px", xs: "8px" },
                }}
              >
                {new Intl.NumberFormat("en-IN").format(increasingNumbers.jobSeekers)}+
              </Box>
              <Typography
                sx={{
                  fontSize: { md: "16px", xs: "13px" },
                  color: "#555",
                  paddingLeft: { md: "20px", xs: "10px" },
                  mt: { xs: "0", md: 1 },
                }}
              >
                Job Seekers
              </Typography>
            </Box>
            <Box fontWeight={600} sx={{ fontSize: { md: "40px", xs: "18px", width:"30%" } }}>
              <Box
                sx={{
                  borderLeft: "1px solid #D7DDE5",
                  minHeight: "25px",
                  lineHeight: 1,
                  paddingLeft: { md: "20px", xs: "8px" },
                }}
              >
                {new Intl.NumberFormat("en-IN").format(increasingNumbers.jobCount)}+
              </Box>
              <Typography
                sx={{
                  fontSize: { md: "16px", xs: "13px" },
                  color: "#555",
                  paddingLeft: { md: "20px", xs: "10px" },
                  mt: { xs: "0", md: 1 },
                }}
              >
                Jobs Listed
              </Typography>
            </Box>
          </Box>
          {device === "mobile" ? (
          <Box textAlign="center" mt={4}>
            <Box
              onClick={() => {
                dispatch(setSignUpOpen(true));
                dispatch(updateAuthTabStatus("Seeker"));
              }}
              sx={{
                fontSize: "16px",
                borderRadius: "8px",
                backgroundColor: "#2A5798",
                color: "#fff",
                padding: "10px 18px",
                fontWeight: "400",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                border: "1px solid #2A5798",
                boxShadow: "0px 4px 4px -1px rgba(0, 0, 0, 0.05)",
                margin:"0 auto"
              }}
            >
              Register to Get Free Job Alerts on <i className="icon-whatsappfill" style={{ marginLeft: "8px" }} />
            </Box>
          </Box>
          ):(<></>)}
        </Container>
      </Box>

      <Box sx={{mt:{md:2, xs:2.5}, minHeight:{md:"222px", xs:"220px"},}}>
        <Loglist device={device} />
      </Box>


      <Box sx={{
        padding: {md:"2em 0 3em", xs:"2em 0 2em"},
        borderBottom: "1px solid #D7DDE5",
        "& .slick-slide": {
          padding: "8px",
        },
        "& .slick-list": {
          margin: "0 -8px",
        },
        "& .slick-dots li button:before": {
          width: "10px",
          height: "10px",
          border: "1px solid #D7DDE5",
          content: "''",
          borderRadius: "50%",
          opacity:1,
        },
        "& .slick-dots li.slick-active button:before": {
          width: "20px",
          height: "10px",
          borderRadius: "36px",
          background: "#D7DDE5",
        },
        "& img": {
          width: "100%",
          height:"auto",
        }
      }}>
        <Container maxWidth="lg">
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Typography
              sx={{ display: "inline-block", border: "1px solid #EDEFF3", borderRadius: "30px", padding: {xs:"6px 10px", md:"8px 12px"}, background: "#FBFCFC", textAlign: "center", mb: 2, fontSize:{xs:"14px", md:"16px"} }}>
              <i className="icon-features" style={{ marginRight: "4px" }} /> Features & Benefits
            </Typography>

            <Typography
              variant="h5"
              component="h3"
              mb={2}
              sx={{ fontSize: { xs: 20, md: 28 } }}
            >
              What Job Seekers will get
            </Typography>
          </Box>
          <Slider {...sliderSettings}>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/whatappss1.png`}
                alt="Automated job suggestions on WhatsApp."
                loading='lazy'
              />
              <Typography sx={{ minHeight: "48px", marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>Automated job suggestions on WhatsApp.</Typography>
            </Box>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/aipowerd1.png`}
                alt="AI-powered resume parsing and profile population."
                loading='lazy'
              />
              <Typography sx={{ minHeight: "48px", marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>AI-powered resume parsing and profile population.</Typography>
            </Box>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/tracking1.png`}
                alt="Tracking of application process completely via our platform"
                loading='lazy'
              />
              <Typography sx={{ minHeight: "48px", marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>Tracking of application process completely via our platform</Typography>
            </Box>
          </Slider>


          <Box
            sx={{ mx: { xs: "auto", lg: 0 }, mt: 5 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >

              <Box
                onClick={() => {
                  dispatch(setSignUpOpen(true));
                  dispatch(updateAuthTabStatus("Seeker"));
                }}
                sx={{
                  transition: "all 0.3s",
                  overflow: "hidden",
                  position: "relative",
                  zIndex: 1,
                  border: "1px solid transparent",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  p: { xs: "8px 22px", md: "10px 2rem" },
                  borderRadius: { xs: "4px", md: "8px" },
                  fontSize: { xs: "14px", md: "16px" },
                  color: "#fff",
                  fontWeight:500,
                  backgroundColor: "#2a5798",
                  "::after": {
                    content: '""',
                    position: "absolute",
                    inset: "0",
                    width: "100%",
                    height: "0%",
                    backgroundColor: "#fff",
                    zIndex: "-1",
                    transition: "all 0.3s",
                  },
                  ":hover": {
                    color: "#2a5798",
                    "::after": {
                      height: "100%",
                    },
                  },
                }}
              >
                Get a Job
              </Box>

              <Link href="/what-employees-will-get">
                <Box
                  sx={{
                    transition: "all 0.3s",
                    overflow: "hidden",
                    position: "relative",
                    p: { xs: "8px 22px", md: "10px 2rem" },
                    borderRadius: { xs: "4px", md: "8px" },
                    fontSize: { xs: "14px", md: "16px" },
                    zIndex: 1,
                    cursor: "pointer",
                    textTransform: "capitalize",
                    color: "#2a5798",
                    backgroundColor: "transparent",
                    border: "1px solid #2a5798",
                    fontWeight:500,
                    ml: "10px",
                    "::after": {
                      content: '""',
                      position: "absolute",
                      inset: "0",
                      width: "100%",
                      height: "0%",
                      backgroundColor: "#2a5798",
                      zIndex: "-1",
                      transition: "all 0.3s",
                    },
                    ":hover": {
                      color: "#fff",
                      "::after": {
                        height: "100%",
                        backgroundColor: "#2a5798",
                      },
                    },
                  }}
                >
                  Know More
                </Box>
              </Link>

            </Stack>
          </Box>

          <Typography
            variant="h5"
            component="h3"
            textAlign="center"
            mb={2}
            mt={4}
            sx={{ fontSize: { xs: 20, md: 28 } }}
          >
            What Job Givers will get
          </Typography>

          <Slider {...sliderSettings}>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/indiamap5.png`}
                alt="Candidates pool"
                loading='lazy'
              />
              <Typography sx={{ minHeight: {md:"96px", xs:"84px"}, marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>Candidates pool of {total_job_seekers}+ (80% teaching and 20% non-teaching profiles)</Typography>
            </Box>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/jobpromotion1.png`}
                alt="We promote your jobs on WhatsApp"
                loading='lazy'
              />
              <Typography sx={{ minHeight: {md:"96px", xs:"84px"}, marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>We promote your jobs on WhatsApp, Google and LinkedIn as well. You may expect a swift response to your postings.</Typography>
            </Box>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/postajobs1.png`}
                alt="You may post jobs (with assistance from AI)"
                loading='lazy'
              />
              <Typography sx={{ minHeight: {md:"96px", xs:"84px"}, marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>You may post jobs (with assistance from AI) and receive applications directly from the candidates with relevancy scores based on AI screening of the applicants list.</Typography>
            </Box>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/locatonfilter1.png`}
                alt="access a candidate's database"
                loading='lazy'
              />
              <Typography sx={{ minHeight: {md:"96px", xs:"84px"}, marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>You may access a candidate's database with filters such as subject, level, board, city, locality, salary, qualifications etc.</Typography>
            </Box>
            <Box sx={{
              border: "1px solid #D7DDE5",
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
              margin: "0 10px",
            }}>
              <Image
                width={1448}
                height={904}
                src={cdn_url + `/jie/web/images/allfeatures1.png`}
                alt="Jobs In Education enables a seamless recruitment process"
                loading='lazy'
              />
              <Typography sx={{ minHeight: {md:"96px", xs:"84px"}, marginTop: "8px", fontSize:{md:"16px", xs:"14px"} }}>Jobs In Education enables a seamless recruitment process right from sourcing relevant candidates, scheduling evaluation rounds to rolling out offers.</Typography>
            </Box>
          </Slider>


          <Box
            sx={{ mx: { xs: "auto", lg: 0 }, mt: 5 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Box
                onClick={() => {
                  dispatch(setSignUpOpen(true));
                  dispatch(updateAuthTabStatus("Employer"));
                }}
                sx={{
                  transition: "all 0.3s",
                  overflow: "hidden",
                  position: "relative",
                  zIndex: 1,
                  border: "1px solid transparent",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  p: { xs: "8px 22px", md: "10px 2rem" },
                  borderRadius: { xs: "4px", md: "8px" },
                  fontSize: { xs: "14px", md: "16px" },
                  fontWeight:500,
                  color: "#fff",
                  backgroundColor: "#2a5798",
                  "::after": {
                    content: '""',
                    position: "absolute",
                    inset: "0",
                    width: "100%",
                    height: "0%",
                    backgroundColor: "#fff",
                    zIndex: "-1",
                    transition: "all 0.3s",
                  },
                  ":hover": {
                    color: "#2a5798",
                    "::after": {
                      height: "100%",
                    },
                  },
                }}
              >
                Hire Talent
              </Box>

              <Link href="/what-employers-will-get">
                <Box
                  sx={{
                    transition: "all 0.3s",
                    overflow: "hidden",
                    position: "relative",
                    p: { xs: "8px 22px", md: "10px 2rem" },
                    borderRadius: { xs: "4px", md: "8px" },
                    zIndex: 1,
                    cursor: "pointer",
                    textTransform: "capitalize",
                    color: "#2a5798",
                    backgroundColor: "transparent",
                    border: "1px solid #2a5798",
                    fontWeight:500,
                    ml: "10px",
                    fontSize: { xs: "14px", md: "16px" },
                    "::after": {
                      content: '""',
                      position: "absolute",
                      inset: "0",
                      width: "100%",
                      height: "0%",
                      backgroundColor: "#2a5798",
                      zIndex: "-1",
                      transition: "all 0.3s",
                    },
                    ":hover": {
                      color: "#fff",
                      "::after": {
                        height: "100%",
                        backgroundColor: "#2a5798",
                      },
                    },
                  }}
                >
                  Know More
                </Box>
              </Link>
            </Stack>
          </Box>
        </Container>
      </Box>

      <Box sx={{ padding: {md:"3em 0 3em", xs:"2em 0 2em"}, }}>
        <Container maxWidth="lg">
          <Testimonials />
        </Container>
      </Box>


      {/* {device === "desktop" ? (
        <></>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={popActiveStatus}
          onClose={() => {
            setTimeout(() => {
              setCheck(true);
            }, 8000);
            setPopActive(false);
          }}
          onOpen={() => setPopActive(true)}
          swipeAreaWidth={56}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              borderTopRightRadius: "16px",
              borderTopLeftRadius: "16px",
              p: "16px 16px 8px",
              width: "100%",
            },
          }}
          variant="persistent"
          className={popActiveStatus ? "Backdrop" : ""}
        >
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textAlign: "center",
              mb: "12px",
            }}
          >
            Use the Jobs In Education App
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Image
                width={24}
                height={24}
                src={`${process?.env?.NEXT_PUBLIC_CDN}/jie/web/App-Icon.png`}
                alt="Jobs in Education App"
                title="Jobs in Education App"
                style={{
                  marginRight: "5px",
                  borderRadius: "4px",
                  boxShadow:
                    "0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
                }}
              />{" "}
              Jobs In Education App
            </Box>
            <Box
              id="app-link"
              component="div"
              onClick={() => {
                router.push(
                  "https://jobsineducation.page.link/?link=https://www.jobsineducation.net&apn=com.jie.ajpl&isi=6444102426&ibi=com.jie.ajpl&ifl=https://apps.apple.com/in/app/jobs-in-education/id6444102426"
                );
              }}
              className="contained-button"
              sx={{ borderRadius: "20px" }}
            >
              Open App
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              className="name"
              style={{ display: "flex", alignItems: "center" }}
            >
              <i
                className="icon-global"
                style={{
                  fontSize: "24px",
                  color: "#F6B333",
                  marginRight: "5px",
                }}
              />
              Browser
            </Box>
            <Box
              id="gtm-btn-browser"
              component="div"
              onClick={() => {
                setTimeout(() => {
                  setCheck(true);
                  onOutsideClick(false);
                }, 6000);
                setPopActive(false);
              }}
              className="outlined-button"
              sx={{ borderRadius: "20px" }}
            >
              Continue
            </Box>
          </Box>
        </SwipeableDrawer>
      )} */}

    </>
  );
}

export default Home;
