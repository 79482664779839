import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { SearchEngine } from "@/features/home/hooks";

function Search({ router, formatKeyword }) {
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleNewPage = async (title) => {
    if (search !== "") {
      setLoading(true);
      const location = localStorage.getItem("currentLocation");
      const { status, url } = SearchEngine(title.toLowerCase(), location);
      if (status) {
        router.push("/" + url);
      } else {
        router.push("/" + formatKeyword(title));
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.target.value != "") {
      handleNewPage(e.target.value);
    }
  };

  return (
    <>
      <TextField
        variant="standard"
        size="large"
        placeholder="Type your job title.."
        autoComplete="off"
        fullWidth
        value={search}
        onKeyDown={handleKeyPress}
        onChange={(e) => setSearch(e.target.value)}
        focused={false}
      />
      <Button
        type="submit"
        variant="text"
        size="large"
        onClick={() => handleNewPage(search.trim())}
        disabled={loading}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: { xs: "16px", md: "18px" },
          p: { xs: "5px 5px", md: "8px 20px" },
          minWidth: "80px",
          height: { xs: "42px", md: "46px" },
          "&.Mui-disabled": {
            opacity: "0.6",
          },
        }}
      >
        {!loading ? (
          <>
            <span className="icon-new-search"></span>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "18px" },
                ml: 1,
              }}
            >
              Search
            </Typography>
          </>
        ) : (
          <Box
            className="customLoader"
            sx={{
              width: "20px !important",
              height: "20px !important",
              m: "0 !important",
            }}
          ></Box>
        )}
      </Button>
    </>
  );
}

export default Search;
